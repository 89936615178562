<template>
  <wrapper>
    <app-header/>
    <div class="projects">
      <container class="projects__form-container">
        <nav class="projects__form-nav form-nav">
          <ul class="form-nav__list">
            <li
                class="form-nav__item"
                :class="{ 'form-nav__item_active' : form === 'search' }"
                @click="switchFormWindow('search')"
            >
              Поиск проекта
            </li>
            <li
                class="form-nav__item"
                :class="{ 'form-nav__item_active' : form === 'create' }"
                @click="switchFormWindow('create')"
            >
              Новый проект
            </li>
            <li
                class="form-nav__item"
                :class="{ 'form-nav__item_active' : form === 'report' }"
                @click="switchFormWindow('report')"
            >
              Отчет
            </li>
          </ul>
        </nav>
        <form class="projects__form form-search" v-show="form === 'search'">
          <div class="projects__form-content">
            <div class="projects__form-element-box">
              <app-input
                  class="projects__form-input"  id="search" label="Название проекта" enable-erase
                  v-model:model-value="search"
                  @erase="clearData" @focus="clearError"
              />
            </div>
            <div class="projects__form-button-group">
              <app-button class="projects__form-button" button-style="button_primary" is-submit @click.prevent="listProjects">
                Поиск
              </app-button>
              <app-button class="projects__form-button" button-style="button_cancel" @click="clearData('search')">
                Сбросить
              </app-button>
            </div>
          </div>
        </form>
        <form class="projects__form form-create" v-show="form === 'create'">
          <div class="projects__form-content">
            <div class="projects__form-element-box">
              <app-input
                  class="projects__form-input"  id="name" label="Название проекта" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('name') }]"
                  v-model:model-value="projectName"
                  @erase="clearData" @focus="clearError"
              />
              <span class="projects__form-error-description form-caption-error" v-show="fieldHasError('name')" v-html="errorBag.name"></span>
            </div>
            <div class="projects__form-button-group">
              <app-button class="projects__form-button" button-style="button_primary" is-submit @click.prevent="createProject">
                Создать
              </app-button>
              <app-button class="projects__form-button" button-style="button_cancel" @click="clearData('projectName')">
                Сбросить
              </app-button>
            </div>
          </div>
        </form>
        <form class="projects__form form-report" v-show="form === 'report'">
          <div class="projects__form-content">
            <div class="projects__form-date-element-box">
              <app-input
                  class="projects__form-input"  id="from" label="Дата (От)" readonly enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('from') }]"
                  v-model:model-value="reportFrom"
                  @erase="clearData" @focus="clearError" @click="showDatepicker('reportFromDatepicker')"
              />
              <span class="projects__form-error-description form-caption-error" v-show="fieldHasError('from')" v-html="errorBag.from"></span>
              <v-date-picker v-show="reportFromDatepickerShow"
                  class="datepicker" mode="date"
                  v-model="reportFromDatepickerValue" :select-attribute="{ dot: 'blue' }"
                  @dayclick="setReportFromDate"
              />
            </div>
            <div class="projects__form-date-element-box">
              <app-input
                  class="projects__form-input"  id="to" label="Дата (До)" readonly enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('to') }]"
                  v-model:model-value="reportTo"
                  @erase="clearData" @focus="clearError" @click="showDatepicker('reportToDatepicker')"
              />
              <span class="projects__form-error-description form-caption-error" v-show="fieldHasError('to')" v-html="errorBag.to"></span>
              <v-date-picker v-show="reportToDatepickerShow"
                  class="datepicker" mode="date"
                  v-model="reportToDatepickerValue" :select-attribute="{ dot: 'blue' }"
                  @dayclick="setReportToDate"
              />
            </div>
            <div class="projects__form-button-date-group">
              <app-button class="projects__form-date-button" button-style="button_primary" is-submit @click.prevent="reportProjects">
                Скачать
              </app-button>
            </div>
          </div>
        </form>
      </container>
      <container class="projects__table-container">
        <div class="projects__table-skeleton skeleton" v-show="! projectsDownloaded">
          <div class="projects__table-skeleton-item-wrapper">
            <div class="projects__table-skeleton-header skeleton-element"></div>
          </div>
          <div class="projects__table-skeleton-item-wrapper">
            <div class="projects__table-skeleton-item skeleton-element"></div>
          </div>
          <div class="projects__table-skeleton-item-wrapper">
            <div class="projects__table-skeleton-item skeleton-element"></div>
          </div>
          <div class="projects__table-skeleton-item-wrapper">
            <div class="projects__table-skeleton-item skeleton-element"></div>
          </div>
          <div class="projects__table-skeleton-item-wrapper">
            <div class="projects__table-skeleton-item skeleton-element"></div>
          </div>
          <div class="projects__table-skeleton-paginator-wrapper">
            <div class="projects__table-skeleton-paginator skeleton-element"></div>
          </div>
        </div>
        <div class="projects__table-wrapper" v-show="projectsDownloaded">
          <app-table class="projects__table">
            <app-thead :headers="headers" @sort="sortProjects"/>
            <app-tr v-for="project in projects" :key="project.id">
              <app-td>
                <app-th>
                  Название
                </app-th>
                <app-tt>
                  {{ project.name }}
                </app-tt>
              </app-td>
              <app-td>
                <app-th>
                  Администратор
                </app-th>
                <app-tt class="projects__table-cell-username">
                  <img :src="getAvatar(project.adminAvatar)" alt="Аватар" class="projects__avatar">
                  <span class="projects__name" v-html="project.adminName"></span>
                </app-tt>
              </app-td>
              <app-td>
                <app-th>
                  Дата создания
                </app-th>
                <app-tt>
                  {{ project.createdAt }}
                </app-tt>
              </app-td>
              <app-td>
                <app-th>
                  Дата изменения
                </app-th>
                <app-tt>
                  {{ project.updatedAt }}
                </app-tt>
              </app-td>
              <app-td>
                <app-th>
                  Кол-во сотрудников
                </app-th>
                <app-tt>
                  {{ project.userNumbers }}
                </app-tt>
              </app-td>
              <app-tb>
                <app-l type="danger" :path="$route" @click.prevent="showModal(project.id)">
                  Удалить
                </app-l>
                <app-l type="primary" :path="
                  {
                    name: 'EditProject',
                    params: {
                      id: project.id
                    }
                  }
                  ">
                  Подробнее
                </app-l>
              </app-tb>
            </app-tr>
          </app-table>
          <app-table-paginator url-name="Projects" v-model:paginator="paginator"/>
        </div>
      </container>
    </div>
  </wrapper>
  <modal-form
      caption="Удаление проекта"
      message="Вы уверены, что хотите удалить данный проект? После удаления проекта его восстановление станет невозможным"
      :confirm="deleteProject" :cancel="closeModal"  :data="modalData" v-show="modalShown"
  />
</template>

<script>
import Wrapper from '@/layouts/Wrapper'
import Container from '@/layouts/Container'
import AppHeader from '@/components/AppHeader'
import AppInput from '@/components/AppInput'
import AppButton from '@/components/AppButton'
import AppTable from '@/components/AppTable'
import AppTableHeader from '@/components/AppTableHeader'
import AppTableRow from '@/components/AppTableRow'
import AppTableTextHeader from '@/components/AppTableTextHeader'
import AppTableColumn from '@/components/AppTableColumn'
import AppTableButtonColumn from '@/components/AppTableButtonColumn'
import AppTableText from '@/components/AppTableText'
import AppTableLink from '@/components/AppTableLink'
import AppTablePaginator from '@/components/AppTablePaginator'
import ModalForm from '@/components/ModalForm'
import { list, create, destroy } from '@/api/projects'
import { isError } from '@/utils/request'
import { camelToSnake, parseDateToClassicString } from '@/utils/helpers'
import { deserialize } from 'deserialize-json-api'
import FileSaver from 'file-saver'

export default {
  name: 'Projects',
  components: {
    Wrapper,
    Container,
    AppHeader,
    AppInput,
    AppButton,
    AppTable,
    AppThead: AppTableHeader,
    AppTr: AppTableRow,
    AppTh: AppTableTextHeader,
    AppTd: AppTableColumn,
    AppTb: AppTableButtonColumn,
    AppTt: AppTableText,
    AppL: AppTableLink,
    AppTablePaginator,
    ModalForm
  },
  data() {
    return {
      headers: [
        {
          field: 'name',
          display: 'Название'
        },
        {
          field: null,
          display: 'Администратор'
        },
        {
          field: 'created_at',
          display: 'Дата создания'
        },
        {
          field: 'updated_at',
          display: 'Дата изменения'
        },
        {
          field: null,
          display: 'Кол-во сотрудников'
        },
        {
          field: null,
          display: ''
        }
      ],
      projects: [],
      projectsDownloaded: false,
      form: 'search',
      projectName: '',
      reportFrom: null,
      reportTo: null,
      reportFromDatepickerShow: false,
      reportToDatepickerShow: false,
      reportFromDatepickerValue: null,
      reportToDatepickerValue: null,
      search: '',
      sort: null,
      paginator: {
        total: undefined,
        count: undefined,
        current_page: 1,
        per_page: 100,
        total_pages: undefined
      },
      errorBag: {},
      modalShown: false,
      modalData: {}
    }
  },
  methods: {
    switchFormWindow(form) {
      this.form = form
    },

    showDatepicker(datepicker) {
      this[datepicker + 'Show'] = true
    },

    setReportFromDate(day) {
      this.reportFromDatepickerShow = false
      this.reportFrom = parseDateToClassicString(day.date)
    },

    setReportToDate(day) {
      this.reportToDatepickerShow = false
      this.reportTo = parseDateToClassicString(day.date)
    },

    getAvatar(path) {
      return path || require('@/assets/svg/avatar.svg')
    },

    getQueryParams() {
      const search = new URLSearchParams(window.location.search)
      const query = {}

      if (search.has('page')) {
        this.paginator.current_page = parseInt(search.get('page'))
      } else {
        query.page = 1
      }

      if (search.has('per_page')) {
        this.paginator.per_page = parseInt(search.get('per_page'))
      } else {
        query.per_page = 100
      }

      this.sort = search.has('sort') ? search.get('sort') : null

      for (const [key, value] of search.entries()) {
        query[key] = value
      }

      if (Object.keys(query).length !== 0) {
        this.$router.replace({ query })
      }
    },

    async listProjects() {
      this.projectsDownloaded = false
      this.projects = []

      const query = {
        page: this.paginator.current_page,
        per_page: this.paginator.per_page
      }

      if (this.sort !== null) {
        query.sort = this.sort
      }

      if (this.search !== '') {
        query.filter =  { name: this.search }
      }

      const response = await list(this.$store.state.user.token, query, ['admin'])

      deserialize(response.data).data.forEach((project) => {
        let adminAvatar = null
        let adminName = null

        if (Object.prototype.hasOwnProperty.call(project, 'admin')) {
          adminAvatar = project.admin.avatar
          adminName = `${project.admin.last_name} <br class="display-992">${project.admin.first_name} <br class="display-992">${project.admin.patronymic ?? ''}`
        }

        this.projects.push({
          id: parseInt(project.id),
          name: project.name,
          adminAvatar: adminAvatar,
          adminName: adminName,
          createdAt: parseDateToClassicString(new Date(project.created_at)),
          updatedAt: parseDateToClassicString(new Date(project.updated_at)),
          userNumbers: project.user_numbers
        })
      })

      this.paginator = response.data.meta.pagination
      this.projectsDownloaded = true
    },

    async createProject() {
      this.errorBag = {}

      const response = await create(this.$store.state.user.token, this.projectName)

      if (isError(response.status)) {
        if (response.status === 422) {
          for (const [key, value] of Object.entries(response.data.errors.validation)) {
            for (const error of value) {
              const curKey = key.replace('data.', '')

              if (Object.prototype.hasOwnProperty.call(this.errorBag, curKey)) {
                this.errorBag[curKey] += `${error}<br>`
              } else {
                this.errorBag[curKey] = `${error}<br>`
              }
            }
          }
        } else {
          this.$notify({
            type: 'error',
            text: response.data.errors.title
          })
        }
      } else {
        this.$notify({
          type: 'success',
          text: response.data.meta.message
        })

        this.listProjects()
      }

      this.projectName = ''
    },

    async deleteProject(data) {
      const response = await destroy(this.$store.state.user.token, data.id)

      if (isError(response.status)) {
        this.$notify({
          type: 'error',
          text: response.data.errors.title
        })
      } else {
        this.$notify({
          type: 'success',
          text: response.data.meta.message
        })

        this.listProjects()
      }

      this.modalData = {}
      this.closeModal()
    },

    async reportProjects() {
      fetch(
          `${process.env.VUE_APP_API_URL}/reports/employees?from=${this.reportFrom}&to=${this.reportTo}`,
          {
            method: 'GET',
            headers: {
              'Accept': 'application/vnd.api+json',
              'Authorization': 'Bearer ' + this.$store.state.user.token
            }
          }
      ).then((response) => {
        if (response.status === 200) {
          response.blob().then((file) => FileSaver.saveAs(file, 'Отчет сотрудники.xlsx'))
        }
      })
    },

    showModal(id) {
      this.modalData.id = id
      this.modalShown = true
    },

    closeModal() {
      this.modalShown = false
    },

    sortProjects(field) {
      const query = {
        page: this.paginator.current_page,
        per_page: this.paginator.per_page
      }

      this.sort = field

      if (field !== null) {
        query.sort = field
      }

      this.$router.replace({ query })
      this.listProjects()
    },

    clearData(field) {
      this[field] = ''
    },

    fieldHasError(field) {
      return Object.prototype.hasOwnProperty.call(this.errorBag, field)
    },

    clearError(event) {
      const field = camelToSnake(event.target.id)

      if (Object.prototype.hasOwnProperty.call(this.errorBag, field)) {
        delete this.errorBag[field]
      }
    }
  },
  computed: {
    currentPage() {
      return this.$route.query.page
    }
  },
  watch: {
    currentPage(newPage, oldPage) {
      if (oldPage !== undefined && newPage !== oldPage) {
        this.paginator.current_page = newPage
        this.listProjects()
      }
    }
  },
  created() {
    this.getQueryParams()
    this.listProjects()
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.projects__form-container {
  margin-bottom: 14px;
  padding: 20px 0;
}

.projects__form {
  padding: 30px 20px 0;
}

.projects__form-nav {
  border-bottom: 1px solid $separator-color;
}

.form-nav {
  padding: 0 20px 20px;
}

.form-nav__list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 15px;
}

.form-nav__item {
  font-size: 14px;
  color: $primary-links-color-inactive;
  cursor: pointer;

  &_active {
    position: relative;
    color: $primary-links-color;

    &::after {
      content: "";
      position: absolute;
      bottom: -21px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $primary-links-color;
    }
  }
}

.projects__form-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.projects__form-element-box, .projects__form-date-element-box {
  flex-basis: 100%;
}

.projects__form-date-element-box {
  position: relative;
}

.projects__form-input {
  width: 100%;
}

.projects__form-error-description {
  display: block;
  margin-top: 10px;
}

.projects__form-button-group, .projects__form-button-date-group {
  display: flex;
  flex-basis: 100%;
  gap: 20px;
}

.projects__form-button, .projects__form-date-button {
  flex-basis: 100%;
}

.projects__avatar {
  display: none;
}

.datepicker {
  position: absolute;
  top: 75px;
  left: 0;
  z-index: 999;
}

/* skeleton */
.projects__table-skeleton {
  display: flex;
  flex-direction: column;
  padding: 25px 0;
}

.projects__table-skeleton-item-wrapper, .projects__table-skeleton-paginator-wrapper {
  padding: 15px 20px;
}

.projects__table-skeleton-item-wrapper {
  border-bottom: 1px solid $border-color;
}

.projects__table-skeleton-header {
  width: 70%;
  height: 23px;
}

.projects__table-skeleton-item {
  width: 100%;
  height: 200px;
}

.projects__table-skeleton-paginator {
  width: 100%;
  height: 23px;
}

@media screen and (min-width: 576px) {
  .projects__form-container {
    margin-bottom: 28px;
    padding: 22px 0 30px;
  }

  .projects__form {
    padding: 40px 20px 0;
  }

  .form-nav__list {
    gap: 30px;
  }

  .form-nav__item {
    font-size: 16px;
  }

  .projects__form-content {
    gap: 30px;
  }

  .projects__form-date-element-box {
    flex-basis: 35%;
  }

  .projects__form-button-group, .projects__form-button-date-group {
    justify-content: flex-end;
  }

  .projects__form-button-date-group {
    flex-basis: calc(30% - 60px);
  }

  .projects__form-button {
    flex-basis: 20%;
  }

  .projects__avatar {
    display: inline;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: $border-radius;
  }

  .projects__table-skeleton-paginator {
    width: 288px;
    height: 23px;
  }
}

@media screen and (min-width: 768px) {
  .projects__form-container {
    padding: 30px 0 35px;
  }

  .form-nav {
    padding: 0 30px 30px;
  }

  .form-nav__item_active::after {
    bottom: -31px;
  }

  .projects__form-element-box {
    flex-basis: calc(65% - 15px);
  }

  .projects__form-button-group {
    flex-basis: calc(35% - 15px);
  }

  .projects__form-button {
    flex-basis: 100%;
  }
}

@media screen and (min-width: 992px) {
  .projects__form-container {
    padding: 30px 0 30px;
  }

  .projects__form {
    padding: 40px 30px 0;
  }

  .projects__avatar {
    position: static;
  }

  .projects__table-cell-username {
    display: flex;
    gap: 20px;
  }

  /* skeleton */
  .projects__table-skeleton-item-wrapper, .projects__table-skeleton-paginator-wrapper {
    padding: 15px 30px;
  }

  .projects__table-skeleton-item {
    height: 60px;
  }
}

@media screen and (min-width: 1200px) {
  .projects__form-container {
    padding: 30px 0 40px;
  }

  .projects__form {
    padding: 40px 40px 0;
  }

  .form-nav {
    padding: 0 40px 30px;
  }

  .form-nav__list {
    gap: 30px;
  }

  /* skeleton */
  .projects__table-skeleton-item-wrapper, .projects__table-skeleton-paginator-wrapper {
    padding: 15px 40px;
  }
}
</style>

<style>
.display-992 {
  display: none;
}

@media screen and (min-width: 992px) {
  .display-992 {
    display: inline;
  }
}
</style>